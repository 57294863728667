.mail-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1100px;
    max-width: 90%;
    border-radius: 12px;
    padding: 20px;
    color: black;
    outline: none;
    max-height: 85vh;
    overflow-y: auto;

    &__header {
        font-size: 18px;
        font-weight: 800;
    }

    &__content-container {
        border: 1px solid #000;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        margin: 20px 0;

        &__item {
            padding: 15px;
            border-bottom: 1px solid #000;
            display: flex;
            flex-direction: column;
            gap: 10px;

            &__icon {
                margin-left: auto;
                cursor: pointer;
            }

            &__row {
                display: flex;
                column-gap: 20px;
                row-gap: 10px;
                flex-wrap: wrap;
                align-items: center;
                min-height: 24px;

                &__title {
                    font-size: 14px;
                    font-weight: 700;
                }

                input {
                    max-width: 500px;
                }
            }

            &__mail-body {
                max-height: 300px;
                min-height: 100px;
                font-size: 13px;
                font-weight: 500;
                overflow-y: auto;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__btns {
        display: flex;
        justify-content: space-around;
    }
}