.infos-modal-container {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  width: 350px;
  cursor: auto;
  .close-btn {
    display: flex;
    background-color: #fff;
    padding: 0 5px;
    position: absolute;
    top: -20px;
    align-self: end;
    align-items: center;
    justify-content: center
  }
  .display-value {
    display: flex;
    align-items: center;
    padding: 0 5px
  }
}

.spot-infomodal-input-address {
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: black;
  }

  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--blue-green);
  }

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: black;
  }
}
.section-title {
  font-weight: 700;
  font-size: 16px;
  color: black; 
}

.spot-key-value-conatiner {
  display: flex;
  gap: 10px;
  .spot-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: black;
    cursor: pointer;
  }
  .spot-key {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    font-size: 14px;
    color: black;
  }
}

.anomalie-info-delete-btn-container {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.flying-box-zone-infowindow-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px 12px 12px;
  .flying-box-zone-title-conatiner {
    font-size: 16px;
    font-weight: 700;
    color: black;
  }
  .flying-box-zone-key-value-conatiner {
    display: flex;
    gap: 10px;
    max-width: 350px;
    .zone-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: black;
    }
    .zone-key {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
      font-size: 14px;
      color: black;
    }
  }
  .flying-box-zone-close-infoWindow {
    position: absolute;
    top: 0;
    right: 0;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    padding: 4px;
    cursor: pointer;
    font-weight: 700;
  }
}
