.action-campaign-page {
  background-color: white;
  border-radius: 30px;
  height: 47px;
  color: black;
  position: fixed;
  top: 30px;
  right: 30px;
  padding: 0px 15px;
  font-weight: 700;
  z-index: 999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 899px) {
    display: none;
  }
}

.banniere-campaign {
  padding: 30px;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 10px;
  justify-content: space-between;
  @media (max-width: 499px) {
    margin: 0px;
    border-radius: 10px 10px 0 0;
    border-bottom: 2px solid black;
    justify-content: center;
    padding: 20px;
  }
  .banniere-title {
    font-size: 25px;
    font-weight: 700;
    color: black;
    @media (max-width: 499px) {
      font-size: 18px;
    }
  }
}

.map-btn-action {
  background-color: var(--black-ffy);
  width: fit-content;
  padding: 8px;
  border-radius: 50%;
  margin: auto;
  cursor: pointer;
  &:hover {
    background-color: rgb(93, 93, 93);
  }
}

.MuiTableCell-root {
  font-size: inherit;
}

.filter-campaign-container {
  z-index: 10;
  background-color: white;
  inset: 0px;
  position: absolute;
  visibility: hidden;
  border-radius: 10px;
  color: black;
  overflow: auto;
  .header {
    height: 100%;
    max-height: 120px;
    border-bottom: 2px solid black;
    font-weight: bold;
    font-size: 20px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0px 30px;
    @media (max-width: 499px) {
      max-height: 78px;
      justify-content: center;
    }
  }
  .filter-section {
    padding: 20px;
    @media (max-width: 499px) {
      padding: 20px 0;
    }
    .filter-content {
      display: flex;
      flex-direction: column;
      padding: 12px;
    }
    .title-section {
      cursor: pointer;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16px;
      border-radius: 60px;
      border: 1px solid #000;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: height 0.3s ease-in-out;
      @media (max-width: 499px) {
        padding: 0px 20px;
        font-size: 14px;
      }
    }
    .notation-container {
      display: flex;
      gap: 4px;
      padding: 36px 12px;
    }
    .filter-container {
      text-transform: uppercase;
      border-bottom: 1px solid black;
      padding: 20px 0;
      font-size: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @media (max-width: 499px) {
        padding: 15px 20px;
      }
      div {
        font-weight: 600;
      }
    }
  }
}

.filter-search-bar {
  border: 1px solid black;
  background-color: #fff;
  height: 50px;
  border-radius: 50px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  input {
    padding: 0px;
    font-size: 16px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }
  &.desktop {
    width: 100%;
    max-width: 400px;
    @media (max-width: 499px) {
      display: none;
    }
  }
}

.campaign-list-container {
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  @media (max-width: 499px) {
    border-radius: 0 0 10px 10px;
  }
}

.reset-filter-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-left: auto;
}

.calendar-filter-container {
  .calendar-range-picker-container {
    align-items: center !important;
    width: 100%;
  }
}

.filter-component-compaigns-container {
  background-color: white;
  display: flex;
}
