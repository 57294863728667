.categorie-title {
  font-size: 15px;
  font-weight: 900;
  font-family: var(--open-sans);
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.categorie-icon {
  cursor: pointer;
}

// RightComponent

.right-component-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  font-weight: 800;
  font-size: 12px;
  font-family: var(--open-sans);
  .right-scrollable {
    padding: 30px 20px 30px 30px;
    flex: 1;
    overflow-y: scroll;
  }
  .categorie-separate {
    padding-bottom: 30px;
    border-bottom: 1px dashed black;
    display: flex;
    flex-direction: column;
    &.between {
      flex-direction: row;
      justify-content: space-between;
    }
    .info-value {
      font-family: Overpass;
      line-height: 1.6;
    }
  }
}

// TimeModal

.time-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #000;
  border-radius: 20px;
  width: 100%;
  max-width: 500px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  font-weight: 800;
  color: black;
  font-size: 12px;
  font-family: Overpass;
  .btn-container {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  .categorie-title {
    margin-top: 0px;
  }
}

.spot-note-section-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .spot-note-section-title {
    font-size: 15px;
    font-weight: 900;
    font-family: var(--open-sans);
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.spot-note-section-content-container {
  display: flex;
  flex-direction: column;
}

.spot-note-section-key-value-conatiner {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  max-width: 400px;
  text-align: right;
  justify-content: space-between;
}
.spot-note-section-content-key {
  font-weight: 800;
  font-size: 12px;
  font-family: var(--open-sans);
  line-height: 1.6;
  text-wrap: nowrap;
}
.spot-note-section-content-value {
  font-weight: 800;
  font-size: 12px;
  font-family: Overpass;
  line-height: 1.6;
}

.spot-note-section-notation-container {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.spot-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #000;
  border-radius: 20px;
  width: 100%;
  max-width: 500px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  font-weight: 800;
  color: black;
  font-size: 12px;
  font-family: Overpass;
  .btn-container {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  .categorie-title {
    margin-top: 0px;
  }
  
  .spot-info-container {
    margin-top: 20px;
    .spot-info-key-value-conatiner {
      display: flex;
      gap: 4px;
    }
    .spot-info-notation-conatiner {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .spot-info-notation-stars-container {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
    }
  }
}
