.DeleteMultipleSelectedItemsModal-first-title {
    font-size: 20px;
    font-weight: 700;
}

.DeleteMultipleSelectedItemsModal-container {
   display: flex;
    align-items: baseline;
    gap: 30px;
}
.DeleteMultipleSelectedItemsModal-sub-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
 }
.DeleteMultipleSelectedItemsModal-container-first-title {
font-size: 13px;
}
.DeleteMultipleSelectedItemsModal-second-title {
    font-size: 15px;
    font-weight: 800;
    margin-top: 23px;

}

.DeleteMultipleSelectedItemsModal-publish-map-btn {
    color: rgba(96, 238, 210, 1);
    background-color: black;
    border-radius: 50px;
    width: 100%;
    cursor: pointer;
    height: 40px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
}