$border-color: #00ffe0;

.section-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  &::before,
  &::after {
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.spin {
  width: 4em;
  height: 4em;
  padding: 0;

  &:hover {
    color: $border-color;
  }
  &::before,
  &::after {
    top: 0;
    left: 0;
  }
  &::before {
    border: 3px solid transparent; // We're animating border-color again
  }
  &:hover::before {
    border-top-color: $border-color; // Show borders
    border-right-color: $border-color;
    border-bottom-color: $border-color;
    transition: border-top-color 0.15s linear,
      // Stagger border appearances
      border-right-color 0.15s linear 0.1s,
      border-bottom-color 0.15s linear 0.2s;
  }
  &::after {
    border: 0 solid transparent; // Makes border thinner at the edges? I forgot what I was doing
  }
  &:hover::after {
    border-top: 3px solid $border-color; // Shows border
    border-left-width: 2px; // Solid edges, invisible borders
    border-right-width: 2px; // Solid edges, invisible borders
    transform: rotate(270deg); // Rotate around circle
    transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s; // Solid edge post-rotation
  }
}

.circle {
  border-radius: 100%;
  box-shadow: none;

  &::before,
  &::after {
    border-radius: 100%;
  }
}
