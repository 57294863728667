.hand-create-formulaire-modal {
  position: relative;
  width: calc(100vw - 40vw - 100px);
  background: white;
  padding: 20px;
  border-right: 1px solid #ccc;
  overflow: auto;

  &__header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin-bottom: 30px;
    justify-content: space-between;

    &__title {
      font-size: 20px;
      font-weight: 800;
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    &__trailing {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}