.prestations {
  width: 100%;
  margin: auto;
  .prestation {
    margin-bottom: 10px;
  }
}

.kml-file-container {
  width: 100%;
  .input-file {
    opacity: 0;
    width: 0.1px;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: normal;
  }
}

.carte-container {
  flex: 1;
  position: relative;
  display: flex;
  .controls {
    margin: 0 10px;
    width: 320px;
    padding: 1rem;
    overflow-y: scroll;
  }
  .map {
    position: relative;
    flex: 1;
    height: 100%;
    border-radius: 20px;
    box-shadow: 1px 1px 5px black;
  }
  .marker-container {
    position: absolute;
    z-index: 1;
    &:hover {
      z-index: 2;
    }
    .paper {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 200px;
    }
  }
}

.carte-container-real-time {
  flex: 1;
  position: relative;
  display: flex;
  .map {
    position: relative;
    flex: 1;
    height: 100%;
    border-radius: 20px;
    box-shadow: 1px 1px 5px black;
  }
  .marker-container {
    position: absolute;
    z-index: 1;
    &:hover {
      z-index: 2;
    }
    .paper {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 200px;
    }
  }
}

.paper-directions {
  padding: 5px 0px 0px 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 10px;
  width: 300px;
  height: 300px;
  .directions {
    overflow-y: scroll;
    padding-right: 15px;
    width: 100%;
    height: 220px;
    .way-points {
      color: black;
      font-size: 13px;
      p {
        margin: 5px 0;
      }
    }
  }
  .time-distance {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 11px;
  }
}

.inputAddressSearch {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 300px;
  height: 40px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  top: 10px;
  right: 60px;
  z-index: 1;
  @media (max-width: 900px) {
    display: none;
  }
}

.pac-container {
  z-index: 999999;
}
