.devis-modal-estimation-container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding: 0 15px;
    .estimation-title {
      font-size: 16px;
      font-weight: 800;
      margin-bottom: 40px;
    }
    .environ {
      font-size: 18px;
      font-weight: 400;
      .estimation-total {
        font-size: 32px;
        font-weight: 400;
        margin-left: 4px;
      }
    }
    p {
      font-size: 12px;
      font-weight: 400;
    }
  }
  