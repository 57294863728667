.flying-hand-add-existing-spot-btn {
  position: absolute;
  right: 10px;
  top: 70px;
  background-color: var(--black-ffy);
  border: 1px solid var(--black-ffy);
  color: white;
  padding: 15px 70px;
  border-radius: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
}

.clusterer-container {
  z-index: 9999;
}