@mixin greenHover($color, $bg-color) {
  color: $color !important;
  border: 1px solid $color !important;
  background-color: $bg-color;
}

@mixin Btn($color, $bg-color) {
  padding: 15px 40px;
  border-radius: 60px;
  font-weight: 700;
  font-size: 32px;
  color: $color;
  background-color: $bg-color;
  border: 1px solid #23272a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  .icon {
    padding-left: 5px;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    .icon {
      // background-color: #23272a;
      transition: all 0.2s ease-in-out;
      transform: rotate(180deg);
    }
  }
}

.redirectBtnWhite {
  @include Btn(#23272a, white);
  &:hover {
    @include greenHover(#60ded2, #23272a);
  }
}
.redirectBtnBlack {
  @include Btn(white, #23272a);
  &:hover {
    @include greenHover(#23272a, white);
  }
}
.simpleBtnWhite {
  @include Btn(#23272a, white);
}
.simpleBtnBlack {
  @include Btn(white, #23272a);
}

.download_btn {
  display: flex;
  height: 70px;
  padding: 10px 0;
  cursor: pointer;
  img {
    border-radius: 30px;
    width: 100%;
  }
}
