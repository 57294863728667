.header-cell {
    font-size: 14px;
    font-weight: bold;
    @media (max-width: 700px) {
        font-size: 12px;
    }
    @media (max-width: 500px) {
        font-size: 10px;
    }
}

.row-cell {
    font-size: 14px;
    @media (max-width: 700px) {
        font-size: 12px;
    }
    @media (max-width: 500px) {
        font-size: 10px;
    }

}

.rotating-icon-table {
    width: fit-content;
    rotate: 0;
    transition: transform 0.1s ease-in-out;
    &.is-open {
      transform: rotate(90deg);
    }
}

.imaged-row-table {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: cover;
}