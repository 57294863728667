@import 'theme/theme.scss';

.image_container {
  position: fixed;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;

  .birdy {
    object-fit: cover;
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 60px;
    z-index: 20s;
  }
  .biglogo {
    position: absolute;
    top: 90px;
    left: 167px;
    z-index: 20s;
    @include mobile {
      width: 60px;
      height: 42px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      top: 12px;
      left: 20px;
    }
  }
  .text {
    position: absolute; 
    left: 95px; 
    top: 380px; 
    font-size: 64px; 
    font-weight: 700; 
    color: white ;
    @include mobile {
      left: 130px;
    }
  }
  .bloctext {
    max-width: 1440px;
    position: absolute; 
    left: 67px; 
    // width: 86%;
    .logos {
      padding-left: 0;
    }    
    .container {
      .content {
        padding-left: 60px !important;
      }
    }
    @include mobile {
      left: 40px;
      top: 83px;
      width: 90%;
      h1 {
        font-weight: 600;
        font-size: 40px !important;
      }
      p {
        font-weight: 600;
        font-size: 30px;
      }
      .btn {
        text-align: -webkit-center;
      }
    }
  }
}
