.single-photo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  border-radius: 10px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;

  .single-photo-header-container {
    display: flex;
    align-items: center;
    .single-photo-header-content-item.unselect{
      color: #666666,
    }
    .single-photo-header-content-item {
      display: flex;
      justify-content: flex-end;
      padding: 20px;
      cursor: pointer;

      .close-icon {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }

  .photo-display {
    display: flex;
    justify-content: center;
    padding: 20px;
    position: relative;
    user-select: none;

    .photo-display-label {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #23272A;
      color: white;
      border-radius: 5px;
      padding: 2px 4px;
    }

    .display-image {
      height: auto;
      max-height: 70vh;
      border-radius: 10px;
      object-fit: contain;
      border: 1px solid black;
      max-width: 60vw;
    }

    .navigation-buttons {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      width: 70vw;

      .prev-button,
      .next-button {
        background-color: #23272a;
        color: black;
        padding: 10px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
      }

      .next-button {
        rotate: 180deg;
      }

      .back-icon {
        width: 30px;
        height: 30px;
        fill: #60eed2;
      }
    }
  }

  .download-button {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: #60eed2;
    cursor: pointer;
    background-color: #23272a;
    padding: 20px;
  }
}
