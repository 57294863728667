.customSelect {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 45px;
  &.multiline {
    height: unset;
  }
  textarea {
    border-radius: 10px;
    resize: none;
    padding: 5px;
    border: 1px solid black;
    font-weight: 600;
  }
  .rightIcon {
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translate(0%, -50%);
    display: flex;
    svg {
      transition: rotate 0.2s;
      rotate: 0deg;
    }

    &.focused {
      svg {
        rotate: 180deg;
      }
    }
  }
  .contentList {
    padding: 10px;
    position: absolute;
    background-color: #fff;
    top: 6px;
    z-index: 999;
    right: 0px;
    left: 0px;
    display: none;
    background-color: #f4f4f4;
    &.focused {
      display: flex;
      flex-direction: column;
      gap: 5px;
      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.5);
    }
    .content-item {
      cursor: pointer;
      color: black;
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
  .labelSelect {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0%, -50%);
    transition: top 0.2s;
    color: black;
    font-weight: bold;
  }
  .labelSelect {
    &.focused {
      zoom: 80%;
      top: 0px;
      background-color: white;
      padding: 2px 15px;
      border-radius: 20px;
    }
  }
  .input-text {
    width: auto;
    max-width: -webkit-fill-available;
    height: 45px;
    font-size: 12px;
    font-weight: 700;
    // box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid black;
    background-color: "none";
    border-radius: 50px;
    padding: 0px 13px;
    // &.empty {
    //   background-color: #f4f4f4;
    // }
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
  .input-text:focus-visible {
    outline: 0;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.5);
  }
}
