.inputAddressSearch {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 300px;
    height: 40px;
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipsis;
    position: absolute;
    top: 10px;
    right: 60px;
    z-index: 1;
    @media (max-width: 900px) {
      display: none;
    }
  }

  .add-new-items {
    width: 38px;
    background-color: white;
    border-radius: 10px;
    height: 38px;
    border: 1px solid grey;
    position: absolute;
    top: 10px;
    right: 370px;
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &.have-drop {
      border: 1px solid #60EED2;
    }
    &:hover {
      // opacity: 0.5;
    }
  }