.desktop-navbar {
  // width: 336px;
  overflow: scroll;
  @media (max-width: 900px) {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  &.is-open {
    width: 268px;
  }
  .navbar-content {
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // padding: 30px 20px;
    .separate {
      margin: 0px 10px;
      border-bottom: 1px solid white;
    }
    .top-navbar-header {
      // text-align: center;
      align-items: center;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      // margin-bottom: 30px;
      // border-bottom: 1px solid white;
      .title-navbar {
        margin: 10px 0;
      }
      .first-name-admin {
        color: white;
      }
      .logo-ffy {
        width: 60px;
      }
    }

    .list-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: min-content;
      padding: 30px 20px;
      // align-items: center;
    }
    .router-list {
      display: flex;
      flex-direction: column;
      gap: 12px 10px;
      width: min-content;
      &.open {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      .route-wrapper {
        margin: 0;
        padding: 0;
        border: none;
        display: contents;
        .item-text {
          position: relative;
          display: flex;
          flex-direction: column;
          // gap: 10px;
          align-self: center;
          .sub-item-container {
            display: flex;
            flex-direction: column;
            gap: 5px;
            // position: absolute;
            // bottom: -14px;
          }
          .item-name {
            font-size: 14px;
            font-weight: 700;
            color: #fff;
            text-wrap: nowrap;
            cursor: pointer;
            &:hover {
              color: #60eed2;
            }
          }
          .sub-item-name {
            font-size: 10px;
            margin-left: 10px;
            color: #fff;
            cursor: pointer;
            &:hover {
              color: #60eed2;
            }
          }
          .item-name.isSelect,
          .sub-item-name.isSelect {
            color: #60eed2;
          }
        }
      }
    }
  }

  span.MuiBadge-badge.MuiBadge-dot.MuiBadge-anchorOriginBottomLeft.MuiBadge-anchorOriginBottomLeftCircular.MuiBadge-overlapCircular.MuiBadge-colorError {
    width: 12px;
    height: 12px;
  }

  .setting-logo-container {
    &.open {
      width: -webkit-fill-available;
      // width: unset;
    }
  }

  .settings-btn-container {
    display: flex;
    width: fit-content;
    cursor: pointer;
    background-color: white;
    border-radius: 50px;
    padding: 6px;
    // margin: 48px 8px;
  }

  .nav-settings-popover {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
      border-radius: 25px 25px 25px 0;
      padding: 25px;
    }
  }
}

.nav-settings-popover-content-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 25px 25px 25px 0;
  padding: 25px;
  .nav-settings-popover-btn-container {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    &.isSelect {
      .nav-settings-popover-btn-icon {
        color: #60eed2;
        fill: #60eed2;
      }

      .nav-settings-popover-btn-text {
        color: #60eed2;
      }
    }

    &:hover {
      .nav-settings-popover-btn-text {
        color: #60eed2;
      }
    }

    &.logout {
      &:hover {
        color: red;

        .nav-settings-popover-btn-icon {
          color: red;
          fill: red;
        }

        .nav-settings-popover-btn-text {
          color: red;
        }
      }
    }

    .nav-settings-popover-btn-icon {
      width: 20px;
      height: 20px;
      fill: #23272a;
    }

    .nav-settings-popover-btn-text {
      font-size: 18px;
      font-weight: 700;
      color: #616161;
    }
  }
}
