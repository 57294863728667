.campaign-card-container {
    position: absolute;
    display: flex;
    max-width: 100vw;
    max-height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.60);
    z-index: 9;
    padding: 50px;

    &.is-mobile {
        padding: 0;
    }

    .campaign-card-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 5px;
        padding: 20px;
    }
}