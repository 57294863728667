.prestation-view {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-top: 20px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &.prestation-view::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari and Opera */
  }
}