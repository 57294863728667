.new-question-section {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__title {
        font-size: 14px;
        font-weight: bold;
    }

    &__questions-list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__item {
            display: flex;
            align-items: center;
            padding: 15px 8px;
            background-color: rgba($color: #D9D9D9, $alpha: 0.2);
            border-radius: 10px;
            gap: 15px;
            cursor: pointer;

            &:hover {
                background-color: rgba($color: #D9D9D9, $alpha: 0.4);
            }

            &__type {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 25px;
                height: 25px;
                padding: 5px;
                background-color: white;
                border-radius: 6px;
            }

            &__title {
                font-size: 12px;
                font-weight: bold;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}