.nav_container {
  height: 40px;
  width: 100%;
  position: fixed;
  top: 15px;
  right: 15px;
  left: 15px;
  z-index: 12;
  display: flex;
  margin: auto;
  max-width: 1440px; 
  .nav {
    display: flex; 
    flex: 1; 
    justify-content: space-between; 
    align-items: center; 
    
    padding: 10px 20px;
    margin-right: 5px;
  }
}
