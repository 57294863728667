.left-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    .rowed-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .campaign-name_date {
            display: flex;
            align-items: baseline;
            gap: 20px;

            .campaign-name {
                font-size: 20px;
                font-weight: 800;
                margin-right: 5px;
            }

            .campaign-date {
                font-size: 12;
                font-weight: 800;
            }
        }
    }

    .campaign-company-name {
        font-size: 16;
        font-weight: 800;
    }

    .campaign-flyers-quantity {
        font-weight: 800;
    }
}