.delete-teams-modal-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 15px;
    box-shadow: 24px;
    z-index: 9999;
    padding: 40px 20px;
    .delete-teams-modal-title {
      font-size: 16px;
      font-weight: 800;
      color: #23272a;
    }
    .delete-teams-modal-subtitle {
      font-size: 14px;
      font-weight: 500;
      color: #23272a;
    }
    .btns-container {
      display: flex;
      width: 100%;
      justify-content: space-around;
      margin-top: 20px;
    }
  }