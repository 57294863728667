.title-item-container {
    display: flex;
    flex: 1;
    font-size: 10px;
    font-weight: 700px;
    align-items: center;
    border-bottom: black solid 1px;
    height: 32px;
    padding: 0px 10px;
    .remove-item {
        visibility: hidden;
        display: flex;
        align-items: center;
    }
    .map-vision {
        visibility: hidden;
        width: 24px;
        height: 24px;
        margin-top: 4px;
        margin-left: 5px;
        display: flex;
        align-self: center;
    }
    .map-vision-true{
        visibility: visible;
        width: 24px;
        height: 24px;
        margin-top: 4px;
        margin-left: 5px;
        display: flex;
        align-self: center;
        color: "#60EED2" !important
    }
    
    &:hover {
        background-color: #f1f1f1 !important;
        .remove-item {
            visibility: visible;
        }
        .map-vision {
            visibility: visible; 
        }
    }
    .title-part-2-container {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Firefox */

        .icons-container {
            display: flex;
            .eye-open {
                visibility: hidden;
            }
        }
        &:hover {
            .eye-open {
                visibility: visible;
            }
        }
    }
}
