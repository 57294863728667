@import "theme/theme.scss";

.container {
  margin: auto;
  max-width: 1440px;
  .content {
    background-color: #23272a;
    padding: 100px 120px;
    .input-title,
    .input-p1,
    .input-p2 {
      width: 300px;
      margin-bottom: 30px;
      .contentList {
        padding: 0;
        .content-item {
          text-align: left;
          font-weight: 700;
          height: 50px;
          padding-left: 10px;
          border-bottom: 1px solid white;
          display: flex;
          align-items: center;
        }
      }
    }
    .input-p1,
    .input-p2 {
      width: 600px;
    }

    .input-p2 {
      margin-top: 20px;
    }
    @include mobile {
      padding: 100px 0 !important;
      .paragraphs {
        @include mobile {
          width: 100%;
        }
      }
      .input-p1,
      .input-p2,
      .input-title {
        :first-child {
          height: 100px;
          font-size: 40px;
        }
        width: 700px;
        margin: 20px auto;
      }
    }
  }
}

.logos {
  display: flex;
  align-items: center;
  width: 200px;
  height: 100px;
  padding: 40px 60px;
  .divider {
    border-left: "1px solid white";
    height: 60px;
    margin: 0 20px 0 10px;
  }
  .ffylogo {
    width: 124px;
    img {
      height: 100%;
    }
  }
  .usecase-logo {
    height: 100%;
  }
}
