.calendar-range-picker {
  
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
      max-height: unset !important;
    }
  
    input {
      padding: 0 !important;
    }
  
    .rdrDateDisplayItem input{
      color: black;
    }
  
    .rdrStartEdge,
    .rdrInRange,
    .rdrEndEdge {
      color: var(--dark-mode) !important;
    }
  
    .rdrDayToday .rdrDayNumber ::after {
      background: var(--dark-mode) !important;
    }
  
    .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span::after,
    .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span::after,
    .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span::after,
    .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span::after {
      background-color: #fff !important;
    }
  
    .rdrDayStartPreview,
    .rdrDayEndPreview,
    .rdrDayInPreview {
      color: var(--dark-mode) !important;
    }
  
    .rdrDateDisplay {
      color: var(--dark-mode) !important;
    }
  
    .rdrDateDisplayWrapper {
      background-color: transparent !important;
    }
  
    .rdrMonths {
      margin: auto !important;
    }
  }
  
  .rdrCalendarWrapper {
    background: #f8f8f8 !important;
  }
  
  .calendar-range-picker-container {
    display: flex;
    flex-direction: row;
    user-select: none;
  }
  
  .calendar-range-picker-shortcuts-container {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e1e1e1;
  }
  
  .calendar-range-picker-shortcuts-title-container {
    padding: 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .calendar-range-picker-shortcuts-title {
    color: var(--dark-mode);
    font-family: 'Work Sans' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: auto;
  }
  
  .calendar-range-picker-shortcuts-items-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 24px 0;
  }
  
  .calendar-range-picker-shortcuts-item-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
    cursor: pointer;
  
    &.isSelected {
      background-color: #e1e1e1;
    }
  }
  
  .calendar-range-picker-shortcuts-item {
    color: var(--dark-mode);
    font-family: 'Work Sans' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 5px 30px;
  }
  
  .rdrCalendarWrapper.rdrDateRangeWrapper.calendar-range-picker {
    margin: auto;
  }