.mail-to-and-cc-section {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #000;
    position: relative;
    padding: 15px;
    gap: 5px;

    &__item {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 14px;
        font-weight: 700;

        &__droppable-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            background-color: #f7f7f7;
            border-radius: 12px;
            padding: 10px;
        }
    }
}