.question-details-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;

    &__title {
        font-size: 14px;
        font-weight: bold;
    }

    &__content {
        &__key-value {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
        }

        &__shared {
            margin-top: 30px;
        }
    }

    &__save-btn {
        margin-top: auto;
    }
}

.question-details-notation {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__preview-container {
        display: flex;
        flex-direction: row;
        gap: 5px;

        .scale-question-mui-icon {
            font-size: 40px;
        }
    }

    &__settings-container {
        display: flex;
        gap: 30px;
    }
}

.question-details-date {
    display: flex;
    align-items: center;
    gap: 16px;
    user-select: none;

    .column {
        display: flex;
        flex-direction: column;
        align-items: center;

        .label {
            font-size: 11px;
            font-style: italic;
            font-weight: 500;
        }

        .placeholder {
            font-size: 18px;
            font-weight: bold;
        }
    }

    .slash {
        font-size: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
}

.question-details-select {
    display: flex;
    flex-direction: column;
    gap: 25px;

    &__items {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &__add-option {
            cursor: pointer;
            user-select: none;
            width: fit-content;
        }

        &__options {
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 350px;
            padding-right: 10px;
            scrollbar-width: thin;
            scrollbar-color: #c1c1c1 transparent;

            &__option {
                display: flex;
                align-items: center;
                border-radius: 30px;
                border: 1px solid #23272A;
                padding: 10px;
                width: 100%;
                gap: 10px;
                -webkit-user-select: none;
                user-select: none;
                font-size: 12px;
                font-weight: 600;
                position: relative;
                box-sizing: border-box;

                &:hover {
                    .question-details-select__items__options__option__delete {
                        display: flex;
                    }
                }

                &__alpha-index {
                    flex-shrink: 0;
                    flex-grow: 0;
                    text-align: center;
                    line-height: 17px;
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    background-color: #23272A;
                    color: white;
                }

                &__text {
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 0 4px;
                }

                &__delete {
                    display: none;
                    position: absolute;
                    right: -10px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
            }
        }
    }
}