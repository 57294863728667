.right-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .settings-btn {
    display: flex;
    justify-content: flex-end;

    svg {
      cursor: pointer;
      padding: 5px;
    }

  }

  .table-btn {
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

.popover-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px 0 5px 5px;

  .popover-item-liste {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #60EEDD;
    }
  }

  .popover-item-red {
    user-select: none;
    color: red;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
  
    &:hover {
      color: #870000;
    }
  }
}