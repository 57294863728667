.question-item {
    display: flex;
    align-items: center;
    padding: 15px 8px;
    background-color: rgba($color: #D9D9D9, $alpha: 0.2);
    border-radius: 10px;
    gap: 15px;
    user-select: none;
    cursor: pointer;

    &:hover {
        background-color: rgba($color: #D9D9D9, $alpha: 0.4);
    }

    &.selected {
        background-color: rgba($color: #D9D9D9, $alpha: 0.6);
    }

    &__type {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 40px;
        height: 25px;
        padding: 5px;
        background-color: white;
        border-radius: 6px;
        min-width: 40px;
    }

    &__title {
        font-size: 12px;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 70%;
    }
}