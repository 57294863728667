.autocomplete-select {
  .item-fields {
    padding: 5px 0px;
    color: black;
    cursor: pointer;

    &:hover,
    &.isSelect {
      background-color: #e9e9e9;
    }
  }
}

.MuiPaper-root {
  .MuiList-root {
    max-height: 400px;
    overflow-y: auto;
  }
}