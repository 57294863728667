$border-color: #00ffe0;

.card-container {
  overflow: hidden;
  border-radius: 7px;
  position: relative;
  border: 3px solid #23272a;

  box-sizing: border-box;
  position: relative;
  // vertical-align: middle;
  &::before,
  &::after {
    box-sizing: inherit;
    content: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.card-container:hover {
  border: 3px solid #00ffe0;
}

.card_over {
  visibility: hidden;
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  // transition: all .5s linear;
  cursor: pointer;
  left: 0;
  right: 0;
  .remove {
    position: absolute;
    top: 10px;
    right: 15px;
  }
  .disable {
    position: absolute;
    top: 10px;
    left: 15px;
  }
}
.card {
  width: 100%;
  position: relative;
  height: 350px;
  transform: scale(1);
  transition: all 0.5s linear;
  .disable-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
.card:hover {
  transform: scale(1.03);
  transition: all 0.5s linear;
}


