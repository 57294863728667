@font-face {
  font-family: 'Fredoka One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fredokaone/v8/k3kUo8kEI-tA1RRcTZGmTlHGCaen8wf-.woff2) format('woff2');
}

;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: "Overpass";
  // font-weight: 600;
}


/* Appliquer Work Sans aux titres */
.title-ffy {
  font-family: 'Work Sans', sans-serif !important;
}

/* Appliquer Overpass au texte */
.text-ffy {
  font-family: 'Overpass', sans-serif !important;
}

:root {
  --grey: #666;
  --grey-light: #999;
  --grey-dark: #333;
  --blue-green: #60eed2;
  --blue-green-hover: #5ec7b2;
  --red-kirby: #FF6580;
  --red-kirby-hover: #ab3e51;
  --orange: #ffcd38;
  --orange-hover: #ff9100;
  --bg-grey: #F9FBFD;
  --open-sans: -apple-system, BlinkMacSystemFont, sans-serif;
  --fredoka: 'Fredoka One', cursive;
  --black-ffy: #23272A;
  --grey-ffy: #F8F8F8;

  --white-mode: #ffffff;
  --dark-mode: #000000;
  --white-mode-img: url("https://icon2.cleanpng.com/20180320/qge/kisspng-computer-icons-desktop-wallpaper-moon-free-moon-svg-5ab135cc0fead8.5508620015215630840652.jpg");
  --dark-mode-img: url("https://www.pngitem.com/pimgs/m/87-870480_crescent-moon-crescent-moon-icon-png-transparent-png.png")
}

#root {
  height: 100%;
  // display: flex;
  color: var(--black-ffy);
  background-color: #23272A;
}

main {
  background-color: #F9FBFD;

}

a {
  text-decoration: none;
  color: inherit;
}


.white_mode_switch::before {
  background-image: var(--white-mode-img);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
}

body {
  height: 100vh;
  margin: 0;
  font-weight: 500;
  font-family: 'Fredoka One', cursive;
  color: #999;
  line-height: 1.4;
  font-size: 14px;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  font-family: "Work sans";
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}