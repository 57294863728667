@import "theme/theme.scss";

.footer_container {
  padding: 85px 90px 50px 80px;
  display: flex;
  justify-content: space-between;
  color: #23272A;
  .column_footer {
    display: flex;
    flex-direction: column;
    .title {
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 25px;
    }
    .text {
      font-size: 16;
      padding: 5px;
      font-weight: 600;
    }
  }
  @include mobile {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .column_footer {
      padding-bottom: 35px;
      text-align: center;
      .title {
        font-size: 30px;
        padding-bottom: 5px;
      }
      .text {
        font-size: 25px;
      }
    }
  }
}

.footer_rights {
  padding: 33px 90px 50px 90px;
  display: flex;
  color: #23272A;
  font-weight: 700;
  justify-content: space-between;
  .ffy_conditions {
    display: flex;
    justify-content: space-between;
    width: 70%;
    font-size: 16px;
  }
  @include mobile {
    font-size: 30px;
    align-items: center;
    flex-direction: column;
    .ffy_conditions {
      padding: 20px 0;
      font-size: 30px;
      width: 100%;
    }
  }
}
