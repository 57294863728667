
.box {
  font-family: var(--open-sans);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: white;
  box-shadow: 1px 1px 2px 2px var(--grey);
  border-radius: 3px;
  padding: 10px;
  text-align: center;
  .content {
    padding: 30px 20px !important;
  }
}
.buttons {
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
}

.loading {
 background-color: red;
 width: 800px;
 height: 10px;
}