.scrolltop {
  // border: 2px solid #00ffe0;
  position: fixed;
  bottom: 5px;
  right: 5px;
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 100%;
    background-color: #00ffe0;
    cursor: pointer;
    transition: all .3s;
    box-shadow: 0px 0px 5px white;
    &:hover {
      transform: scale(1.09);
      transition: all .5s;
      animation: loader 1.5s infinite alternate;
    }
  }
}

@keyframes loader {
  0% {
    rotate: 0deg;
  }
  50% {
    rotate: 30deg;
  }
  100% {
    rotate: -30deg;
  }
}
