.component-photo-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 40vw;
  padding: 30px;
  overflow: auto;

  .photo-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }

  .photo-details-actions {
    display: flex;
    justify-content: space-around;
    position: relative;
    margin-top: auto;
  }
  .message-public {
    position: absolute;
    top: -82px;
    background-color: white;
    border: 1px solid;
    border-radius: 30px;
    padding: 14px 16px;
    border-bottom-right-radius: 0px;
    font-size: 10px;
    font-weight: 700;
    width: 310px;
}
  
}
