.drawing-controls {
  background-color: white;
  padding: 10px;
  position: absolute;
  right: 50%;
  top: 5px;
  display: flex;
  gap: 10px;
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid grey;
    height: 25px;
    width: 25px;
  }
}
