@import 'theme/theme.scss';

.header-container {
  background-color: white; 
  border-radius: 10px; 
  width: 100%; 
  height: 300px; 
  margin-bottom: 20px; 
  display: flex;

  .inputs {
    display: flex; 
    flex-direction: column; 
    flex: 1; 
    justify-content: center; 
    position: relative
  }
  .save {
    width: 200px;
    margin: 0 auto;
  }
  .input-div {
    margin: 10px 5px; 
    padding: 5px 10px; 
    border-radius: 10px; 
    background-color: white;
  } 
  @include mobile {
    flex-direction: column;
    height: 1000px;
    .inputs {
      min-height: 100px;
      .input-div {
        height: 100px;
        :nth-child(1n) {
          height: 100px;
          font-size: 30px;
        }
      }
    }
    .upload-left {
      height: 600px;
    }
  }
}