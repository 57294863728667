.left-layout-container {
  position: absolute;
  top: 90px;
  left: 10px;
  max-height: calc(100% - 180px);
  min-height: 200px;
  width: 250px;
  z-index: 9999;
  padding: 10px;
  border-radius: 10px;
  padding-bottom: 50px;
  font-weight: 800;
  overflow-y: scroll;
  font-family: "Overpass";

  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }


  .prestation-container {
    margin-bottom: 30px;

    .bookmark-container {
      border-radius: 10px 10px 0 0;
      background-color: #fff;
      width: 100%;
      display: flex;
      justify-content: center;
      color: #60eed2;

      .bookmark-content {
        padding: 10px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
    }

    .mission-zones-container {
      background-color: #fff;
      padding: 20px;
      height: unset;
      visibility: visible;

      &.hidden {
        padding: 0px;
        height: 0px;
        visibility: hidden;
      }

      .btn-add-mission {
        background-color: #60eed2;
        border-radius: 5;
        padding: 5;
        text-align: center;
        color: #fff;
        cursor: pointer;

        &:hover {
          background-color: #4ec5ad;
        }
      }
    }
  }
}

.zone_trash {
  display: none;
}

.name-of-zone:hover {
  background-color: #f1f1f1 !important;

  &:hover {
    border: 2px solid #60eed2;

    .zone_trash {
      display: flex;
    }
  }
}



.mission_zone {
  padding: 8px 0;
  position: relative;

  .remove-btn {
    position: absolute;
    right: 0px;
    visibility: hidden;
  }

  &:hover {
    background-color: #e9e9e9 !important;

    .remove-btn {
      visibility: visible;
    }
  }

  .input-missionZone-container {
    width: fit-content;

    .input-missionZoneName {
      width: 100%;
      height: 100%;
      border: none;
      font-size: 16px;
      background-color: transparent;

      &:focus {
        background-color: white;
        border: 1px solid black;
      }
    }
  }
}

.zone-container {
  display: flex;
  width: 100%;
  align-items: center;

  .colored-square {
    width: 15px;
    height: 15px;
    margin: 0 10px 0 0;
  }

  .right-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-right-container {
      display: flex;
      align-items: center;

      .hidden-icon,
      .trash {
        display: flex;
        align-items: center;
        visibility: hidden;
      }
    }
  }

  &:hover {
    background-color: #60eed2 !important;

    .icon-right-container {
      .trash {
        visibility: visible;
        cursor: pointer;
      }

      .hidden-icon {
        visibility: visible;
      }
    }
  }
}


.publish-map-btn {
  color: rgba(96, 238, 210, 1);
  font-size: 15px;
  background-color: black;
  font-weight: 700;
  border-radius: 50px;
  width: 100%;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
}