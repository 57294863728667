.slot-card {
  display: grid;
  grid-template-columns: repeat(7, 1fr) 70px 50px;
  align-items: center;
  background-color: var(--grey-ffy);
  padding: 10px;
  cursor: pointer;

  .slot-card-teams {
    width: 70px;
    height: 50px;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .color-1-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 101%;
      height: 100%;
      clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
      border-radius: 5px;
    }
    .color-2-container {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 101%;
      height: 100%;
      clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
      border-radius: 5px;
    }
  }
  .slot-card-sm-names {
    min-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .slot-card-date {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 50px;
    max-width: 110px;
  }

  .slot-card-sm-names {
    min-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .slot-card-time {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 50px;
  }

  .slot-card-city {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 50px;
  }

  .slot-card-state {
    padding: 10px;
    border-radius: 50px;
    margin-left: 20px;

    .slot-card-state-dot {
      padding: 0 8px;
      margin-right: 10px;
      border-radius: 50px;
    }
  }
}

#slot-teams-popover {
  font-weight: 700;
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    border: 1px #000 solid;
    border-radius: 0 15px 15px 15px;
  }
  .team-leader-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 14px;
    gap: 20px;
    border-bottom: 1px #000 solid;
    justify-content: space-between;
  }
  .new-team-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    .assigned-teams-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .assigned-teams-list-container {
        display: flex;
        gap: 10px;
        .assigned-team-card {
          position: relative;
          width: 55px;
          height: 50px;
          border-radius: 7px;
          cursor: pointer;
          &:hover {
            opacity: 0.8; 
          }
          .delete-assigned-team-card {
            position: absolute;
            top: 3px;
            right: 3px;
            cursor: pointer;
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
    .new-team {
      cursor: pointer;
      user-select: none;
    }
  }
}
