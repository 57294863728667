.delete-cadence-zone-spot-modal-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 15px;
    z-index: 9999;
    padding: 40px 20px;
    min-width: 400px;
    .delete-cadence-zone-spot-modal-title {
      font-size: 16px;
      font-weight: 700;
      color: #23272a;
    }
    .btns-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 20px;
    }
  }