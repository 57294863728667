.clusterer-count-label {
  margin-right: 9px;
  margin-bottom: 9px;
}

.cadence-tabs-btns-container {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  font-size: 14px;
  font-weight: 700;
  font-weight: bold;
  display: flex;
  align-items: center;
  z-index: 999;
  height: 50px;
  user-select: none;
  background-color: white;
  .cadence-tab-btn {
    cursor: pointer;
    border: 1px solid var(--black-ffy);
    width: 270px;
    display: flex;
    justify-content: center;
    align-items: center;    height: 100%;
    &.selected {
      background-color: var(--black-ffy);
      color: white;
    }
  }
}

.cadence-main-action-btn {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  height: min-content;
  background-color: var(--black-ffy);
  border: 1px solid var(--black-ffy);
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  z-index: 999;
  &.disabled {
    background-color: var(--grey-light);
    cursor: not-allowed;
  }
}
