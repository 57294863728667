@import "theme/theme.scss";

.choosecase_container {
  padding: 20px 25px;
  width: 94%;
  .content_case {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      .btn {
        width: 200px;
        padding-right: 30px;
      }
      .content_bloc_list {
        flex: 1;
        display: flex;
        .divider {
          height: inherit;
          border-left: 1px solid #23272a;
        }
        .content_list {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-left: 15px;
          .contentList {
            padding: 0;
            .content-item {
              text-align: left;
              font-weight: 700;
              height: 50px;
              padding-left: 10px;
              // background-color: #dfdfdf79;
              border-bottom: 1px solid white;
              display: flex;
              align-items: center;
            }
          }
          .action-input {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .inputs-solution {
              display: flex;
              flex-direction: column;
              flex: 1;
              max-height: 300px;
              overflow-y: auto;
              &::-webkit-scrollbar {
                width: 12px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: gray;
                border-radius: 4px;
                height: 200px;
              }
            }
            .solution-content {
              display: flex;
              flex-direction: column;
              margin-top: 13px;
              .list-solution-action {
                margin-left: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .list {
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  .list-item {
                    margin-bottom: 6px;
                  }
                }
              }
            }
            .action {
              cursor: pointer;
              padding-left: 10px;
            }
          }
        }
      }
    }
    @include mobile {
      padding: 35px 20px;
      .content {
        flex-direction: column;
        align-items: center;
        .btn {
          width: max-content;
          padding-bottom: 50px;
          .simpleBtnWhite {
            font-size: 30px !important;
            padding: 20px 40px !important;
          }
        }
        .content_list {
          justify-content: center;
        }
        .action {
          :first-child {
            height: 50px;
          }
        }
        .inputs-solution {
          max-height: 500px !important;
        }
        .content_bloc_list {
          .divider {
            height: 350px;
          }
          .action-input {
            width: 700px;
            // height: 270px;
            margin: 20px 0;
            :last-child {
              // height: 100px;
              font-size: 40px;
            }
          }
          .argument {
            font-size: 30px;
          }
        }
      }
    }
  }
}
