.maps-layout-container {
  position: absolute;
  background-color: white;
  width: 55px;
  border-radius: 10px 0 10px 10px;
  display: flex;
  transition: width 0.5s ease;

  &.layoutOpen {
    width: 400px;
    background-color: white;

    @media (max-width: 900px) {
      width: 350px;
    }

    @media (max-width: 500px) {
      width: 300px;
    }

    .left-layout-chevron {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .tabs {
    width: 50px;
    height: 100%;
    padding: 5px 0 5px 5px;

    span {
      display: none;
    }

    .tab {
      display: flex;
      justify-content: flex-start;
      padding: 12px 0;
      border-radius: 10px;

      &:hover {
        background-color: #60eed228;
      }

      svg {
        margin-left: 5px;
      }
    }
  }

  .content {
    color: black;
    border-left: 1px solid black;
    padding: 10px;
    width: 100%;
    overflow-wrap: anywhere;
    max-height: 400px;
    overflow: auto;
    opacity: 100%;
    transition: opacity 5s ease;
  }

  .display-none {
    display: none;
    opacity: 0%;
  }

  .maps-layout-title {
    overflow: hidden;

    .maps-layout-logo,
    .maps-layout-logo_focus {
      display: flex;
      width: 35px;
      height: 35px;
      padding: 5px;
      transition: width 0.5s ease-in-out;

      svg {
        fill: black;
        z-index: 999;
      }
    }
  }

  .left-layout-chevron {
    width: 30px;
    min-height: 30px;
    background-color: white;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      transition: transform 0.5s ease;
      rotate: 180deg;

      @media (max-width: 900px) {
        rotate: 0deg;
      }
    }
  }
}

.real-map-graph {
  position: absolute;
  right: 49px;
  background: white;
  bottom: 10px;
  border-radius: 3%;
}


.campaign-map-graph {
  position: absolute;
  right: 55px;
  background: white;
  bottom: 105px;
  border-radius: 3%;
}

.tooltipe-container-custom {
  padding: 10;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 10;
}

.graph-bottom-legend {
  display: flex;
  justify-content: space-around;
  font-family: "Overpass";
  font-weight: 700;
  font-size: 10px;
  padding: 10px;

}

.left-layout-graph {
  position: absolute;
  left: -30px;
  border: solid 3px black;
  background: white;
  bottom: 46%;
  display: flex;
  border-right: none;
  border-top-left-radius: 25%;
  border-bottom-left-radius: 25%;
  width: 30px;
  height: 38px;
  justify-content: center;
  align-items: center;

}