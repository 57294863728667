@import "theme/theme.scss";

.intro {
  .container {
    margin: auto;
    max-width: 1440px;
    .content {
      background-color: #23272a;
      padding: 100px 120px;
      h1 {
        color: white;
        margin: 0;
      }
      .paragraphs {
        p {
          font-size: 32px;
          font-weight: 600;
          color: white;
        }
        width: 90%;
        // margin: 45px 0;
        .paraph2 {
          font-size: 17px;
        }
        @include mobile {
          width: 100%;
          p {
            font-size: 35px !important;
          } 
          .paraph2 {
            font-size: 28px;
          }
        }
      }
      @include mobile {
        .btn {
          text-align: -webkit-center;
          padding: 70px 0 0 0;
        }
      }
    }
    @include mobile {
      padding: 0 70px;
    }
  
  }
  .logos {
    display: flex;
    align-items: center;
    width: 400px;
    padding: 40px 60px;
  
    .ffylogo {
      width: 124px;
      height: 102px;
    }
    
    .usecase-logo {
      height: 102px;
      img {
        width: 100%;
      }
    }
  }
}