.service-photo-container {
  margin-bottom: 20px;

  .service-photo-header {
    display: flex;
    border: 1px solid black;
    border-radius: 50px;
    padding: 10px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .dropdown-icon {
      margin-right: 8px;
      transition: transform 0.3s ease;
    }

    .open {
      transform: rotate(180deg);
    }

    .service-name {
      flex-grow: 1;
      text-align: center;
    }
  }
}
