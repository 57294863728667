@import 'theme/theme.scss';

.videoPlayer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  .upload-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    .input-title {
      padding: 10px 0px;
      border-radius: 5px;
      flex: 1;
    } 
    
  }
  @include mobile {
    .input-title {
      :first-child {
        font-size: 40px;
      }
    }
    .upload {
      :first-child {
        width: 100px;
        height: 80px;
      }
    }
  }
  :global(iframe),
  .thumbnail {
    // border-radius: 20px;
    border: none;
    width: 100%;
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}

.thumbnail {
  height: 554px;
  position: relative;
  &Img {
    height: 100%;
    width: 100%;
    // border-radius: 20px;
    object-fit: cover;
    filter: brightness(60%);
  }
  .playIcon svg,
  .playIconMain svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
  .playIcon svg {
    height: 80px;
    width: 80px;
  }
}
