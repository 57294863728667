.templates-list {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__data {
            display: flex;
            flex-direction: column;

            &__title {
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
            }

            &__questions-count {
                font-size: 13px;
                font-weight: 600;
                line-height: 16px;
            }
        }

        &__btns {
            display: flex;
            align-items: center;
            gap: 15px;
        }
    }
}