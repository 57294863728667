@import 'theme/theme.scss';

.action-btns {
  position: sticky; 
  bottom: 0px; 
  text-align: center; 
  background-color: white; 
  left: 53px; 
  right: 53px; 
  padding: 5px;
  @include mobile {
    .save, .show {
      font-size: 30px;
      width: 250px;
      height: 80px;
    }
  }
}