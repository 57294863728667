.slot-view {
    display: flex;
    flex: 1;
    &:hover {
        .active-slot-view {
            background-color: #f1f1f1;
        }
    }
    .active-slot-view {
        width: 5px;
        background-color: unset;
        &.active {
            background-color: #60EED2;
        }
    }
}

.add-slot-btn {
    background-color: #000;
    padding: 5px 15px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    cursor: pointer;
    &:hover {
        background-color: #4ec1aa
    }
}

.bar-drop{
    border: 1.8px solid black;
    font-size: 12px;
    padding-left: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative;
    color: black;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Firefox */
}