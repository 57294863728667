.photo-header {
  display: flex;
  border: 1px solid black;
  border-radius: 50px;
  padding: 10px;
  margin: 10px 70px 10px 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .dropdown-icon {
    margin-right: 8px;
    transition: transform 0.3s ease;
  }

  .open {
    transform: rotate(180deg);
  }

  .photo-info {
    flex-grow: 1;
    text-align: center;
  }
}

.photo-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.photo-item {
  position: relative;
  cursor: pointer;
  width: 100px;
  .published-picture-logo-container {
    position: absolute;
    bottom: 10px;
    left: 5px;
    background-color: black;
    border-radius: 0 10px 0 0;
    padding: 6px;
    height: 20px;
    width: 20px;
  }
}

.select-icon {
  position: absolute;
  top: 0;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-icon-box{
  position: absolute;
  top: 0;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check {
  fill: #60eed2 !important;
  width: 20px;
  height: 20px;
}

.photo-check-in-out-label {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #23272A;
  color: white;
  border-radius: 0 0 5px 0;
  padding: 2px 4px;
  font-size: 11px;
}

.photo-image {
  width: 100px;
  height: 100px;
  padding: 5px;
  object-fit: cover;
}
