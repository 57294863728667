.formControl {
  :global(.MuiOutlinedInput-root) {
    border: black solid 1px;
    background: white;
    border-radius: 40px;    &:global(.MuiInputBase-multiline) {
      height: unset;
    }
  }

  :global(.MuiInputLabel-root) {
    font-family: "Overpass";
    color: black;
    font-size: 18px;
    font-weight: bold;
  }

  :global(.MuiInputLabel-root.Mui-focused) {
    padding: 0 10px;
  }

  .empty {
    background-color: transparent;
    :global(.MuiInputLabel-root.Mui-focused) {
      transform: translate(14px, -9px) scale(0.8);
      background-color: white;
      font-size: 16px;
    }
  }

  .full {
    :global(.MuiInputLabel-root) {
      transform: translate(14px, -9px) scale(0.8);
      background-color: white;
      padding: 0 10px;
    }
  }

  :global(.MuiOutlinedInput-root) {
    fieldset {
      border: none;
    }

  }
  :global(.Mui-focused) {
    fieldset {
      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25)
    }
  }

  :global(.Mui-disabled) {
    cursor: default;
    &:global(.MuiInputLabel-root) {
      filter: opacity(.5);
      background-color: transparent;
    }
    &:global(:not(.MuiInputLabel-root)) {
      filter: opacity(.9);
      background-color: #ffffff;
      border-radius: 11px;
    }
  }
}

.noShadow {
  :global(.MuiOutlinedInput-root):hover {
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25)
  }
}

.textfield {
  border-radius: 5px;
  &.empty {
    background-color: #ffffff;
  }
  &.full {
    background-color: #fff;
  }
}

.label {
  position: absolute;
  top: 0;
  left: 15px;
  transform: translateY(-55%);
  color: black;
  font-size: 12px;
  background-color: white;
  padding: 5px;
}

:global(.MuiAutocomplete-endAdornment) {
  top: unset;
}