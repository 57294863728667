.prestation-card {
  margin: 7px 0px;
  background-color: var(--grey-ffy);
  padding: 20px 40px;

  .prestation-type {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;

    >div {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    >svg {
      transform: rotate(270deg);
      transition: all 0.3s;
    }

    &.open>svg {
      transform: rotate(135deg);
    }

    .prestation-collabse-bar {
      display: flex;
      justify-content: space-between;

      .prestation-right-bar {
        display: flex;
        align-items: center;
        gap: 20px;

        .presta-icon {
          // transrotate(0deg)
          transition: transform 0.2s ease;

          &.open {
            transform: rotate(90deg);
            transition: transform 0.2s ease;
          }
        }

        &__mail-badges-container {
          display: flex;
          flex-direction: row;
          gap: 10px;

          &__mail-badge {
            display: flex;
            height: 40px;
            align-items: center;
          }
        }
      }

      .prestation-left-bar {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }

  .slots-list {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: white;

    .slots-infos {
      display: flex;
      justify-content: space-between;

      .slots-open-component {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        svg {
          transition: all 0.3s;
        }

        &.open svg {
          transform: rotate(135deg);
        }
      }
    }

    .slot-container {
      display: grid;
      margin-top: 20px;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
  }
}


// PRESTATION CONFIG //

.flyers-quantity {
  display: flex;
  gap: 40px;
  flex-direction: row;
  align-items: center;
}

.client-flyers-quantity {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}

.presta-info-separate {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;

  .sub-infos {
    display: flex;
    gap: 40px;
  }

  .separate-items {
    &.left {
      border-left: 1px solid black;
      padding-left: 40px;
    }

    &.right {
      border-right: 1px solid black;
      padding-right: 40px;
    }

    &__formulaire {
      display: flex;
      align-items: center;
      gap: 20px;

      &__btns {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}


.formulaire-popover-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 8px;

  &__item {
    user-select: none;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: rgb(94, 94, 94);
    }
  }
}