.confirm-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}
.confirm-modal-overlay.open {
    opacity: 1;
    visibility: visible;
}
.confirm-modal-content {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    width: 1000px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}
.confirm-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.confirm-modal-title {
    font-size: 20px;
    font-weight: 800;
    color: #23272A;
}
.close-button {
    cursor: pointer;
}
.confirm-modal-description {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
}
.confirm-modal-btns {
    display: flex;
    gap: 120px;
    justify-content: space-evenly;
    padding: 0px 160px;
}
.devis-modal-btn {
    user-select: none;
    color: white;
    background-color: #23272A;
    border-radius: 50px;
    width: 100%;
    cursor: pointer;
    height: 44px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
}
