.black-btn {
    height: min-content;
    background-color: var(--black-ffy);
    border: 1px solid var(--black-ffy);
    color: white;
    padding: 15px 30px;
    border-radius: 50px;
    font-weight: bold;
    // width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    user-select: none;

    &.reverse {
        background-color: white;
        color: var(--black-ffy);
    }

    &.center {
        text-align: center;
        justify-content: center;
    }

    &.disabled {
        background-color: var(--grey-light);
        cursor: not-allowed;
    }
}