.infos-missionCandidate-container {
  .missionCandidate-container {
    background-color: var(--grey-ffy);
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .missionCandidate-state-container {
      padding: 10px;
      border-radius: 50px;
      margin-left: 20px;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }

      .container-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 160px;
        font-size: 12px;
        font-weight: bold;

        .missionCandidate-state-dot {
          padding: 2px 9px;
          margin-right: 10px;
          border-radius: 50px;
        }
      }
    }
  }

  .missionInfos-container {
    .missionInfos-check {
      display: grid;
      grid-template-columns: 100px 1fr 30px;
      grid-gap: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: bold;

      .slot-pen {
        background-color: black;
        padding: 5px;
        border-radius: 50px;
        cursor: pointer;
      }
    }

    .missionInfos-flyers-stats {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      font-size: 14px;
      font-weight: bold;

      .left-column, .right-column {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 1;
        div {
          margin-bottom: 10px;
        }
      }
    }

    .missionInfos-buttons {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }
  }
}

.popover-missionCandidate-container {
  border: 1px solid black;
  padding: 5px;
  border-radius: 0px;

  .popover-item {
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 800;

    &:hover {
      text-shadow: 1px 1px 2px grey;
    }
  }
}