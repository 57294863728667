@import "theme/theme.scss";

.v_chooseffy_container {
  padding: 75px 30px 75px 140px;
  .title {
    h3 {
      display: flex;
      font-size: 40px;
      font-weight: 700;
      color: #23272a;
      line-height: 1;
      margin: 0;
      span {
        font-size: 45px;
        font-weight: 700;
      }
      @include mobile {
        flex-direction: column;
        align-items: center;
        font-style: italic;
        font-size: 35px;
        span {
          font-size: 50px;
        }
      }
    }
  }
  .content_container {
    // padding: 100px 20px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 50px 0;
      .btn {
        flex: 1;
        padding-right: 60px;
      }
      .content_bloc_list {
        flex: 2;
        display: flex;
        .divider {
          height: inherit;
          border-left: 1px solid #23272a;
        }
        .content_list {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-left: 60px;
          .argument {
            font-family: "Work Sans";
            font-size: 20px;
            font-weight: 600;
            padding: 30px 0;
            max-width: 325px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  @include mobile {
    padding: 75px 30px;
    .content {
      flex-direction: column;
      align-items: center;
      .btn {
        padding-bottom: 50px;
      }
      .content_bloc_list {
        .content_list {
          .argument {
            font-size: 30px !important;
          }
        }
      }
    }
  }
}
