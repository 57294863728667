.status-badge {
    display: flex;
    gap: 12px;
    border-radius: 50px;
    width: fit-content;
    height: 34px;
    align-items: center;
    margin-top: 18px;
    padding: 0 30px 0 0;
    .circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-left: 12px;
    }
    .text {
      font-size: 12px;
      font-weight: 800;
    }
  }