.DateModal_container {
    
    .DateModal_Box_container {
        position: relative;
        padding: 20px 10px;
        
        .DateModal_Box_header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .DateModal_Box_header-title {
                display: flex;
                align-items: center;
            }
            
            .DateModal_Box_header-switch {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
        
        .DateModal_Box_tarif-horaire {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 0 !important;
            margin-bottom: 15px;
        }

        @media screen and (min-width: 900px) {
            .DateModal_Box_tarif-horaire {
                .customSelect {
                    width: 50%;
                }
            }
        }

        .DateModal_Box_tarif-date {
            margin-bottom: 15px;
            
            .DateInfos_container {
                display: flex;
                gap: 20px;
                flex-direction: column;
                margin: 20px 0;
                
                .DateInfos_DateTimePicker {
                    width: 100%;
                }

                &:last-of-type {
                    margin-bottom: 0px;
                }
            }

            @media screen and (min-width: 900px) {
                .DateInfos_container {
                    flex-direction: row;
                }
            }
        }
    }

    .DateModal_Box_save-btn {
        background-color: #000000;
        padding: 10px 15px;
        color: #fff;
        border-radius: 10px;
        text-align: center;
        margin-top: 10px;
        cursor: pointer;

        &:hover {
            background-color: #00000099
        }
    }

    .DateModal_Box_SMstate {
        display: flex;
        flex-direction: column;
        color: black;
        gap: 20px;
        padding-top: 10px;
        text-align: justify;

        .DateModal_Box_SMstate_button-container {
            display: flex;
            gap: 20px;
            flex-direction: column;

            .DateModal_Box_SMstate_button {
                color: white;
                background-color: #000000;
                padding: 10px 15px;
                border-radius: 10px;
                cursor: pointer;
                text-align: start;

                &:hover {
                    background-color: #00000099
                }
            }
        }
        
        @media screen and (min-width: 500px) {
            .DateModal_Box_SMstate_button-container {
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    @media screen and (min-width: 500px) {
        .DateModal_Box_container {
            padding: 40px;
        }
    }
}