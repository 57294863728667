.button {
  font-family: var(--fredoka);
  font-size: 17px;
  color: white;
  display: flex;
  align-items: center;
  gap: 15px;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  svg {
    height: 1.3rem;
  }
  &:global(.red) {
    background-color: var(--red-kirby);
    &:hover {
      background-color: var(--red-kirby-hover);
    }
  }
  &:global(.orange) {
    background-color: #ffcd38;
    &:hover {
      background-color: #ff9100;
    }
  }
  &:global(.green) {
    background-color: var(--blue-green);
    &:hover {
      background-color: var(--blue-green-hover);
    }
  }
  &:global(.grey) {
    background-color: var(--grey-light);
    &:hover {
      background-color: var(--grey);
    }
  }

  &:global(.disabled) {
    filter: opacity(.5);
    pointer-events: none;
    &:hover {
      cursor: default;
    }
  }
}

.reverse {
  flex-direction: row-reverse;
}

.button_v2 {
  // font-family: Work Sans;
  font-size: 15px;
  color: white;
  border: none;
  background-color: #23272A;
  border-radius: 50px;
  padding: 10px 25px;
  font-weight: 700;
  cursor: pointer;
  @media (max-width: 900px) {
    font-size: 13px;
    padding: 7px 18px;
  }
  @media (max-width: 500px) {
    font-size: 8px;
    padding: 4px 7px;
  }
}