@import "theme/theme.scss";

.statistics_container {
  padding: 0px 0px 35px 10px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .action {
      cursor: pointer;
      padding-left: 10px;
      @include mobile {
        :first-child {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
  .statistics_container {
    padding: 40px 0 35px 0;
    display: flex;
    justify-content: center;
    margin: auto;
    max-width: 980px;
    .statistics {
      display: flex;
      justify-content: center;
      align-items: center;
      .divider {
        border-left: 1px solid #23272a;
        margin: 0 25px;
        height: 210px;
      }
      .stat {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 200px;
        .input-title {
          // width: 200px;
          // background-color: #23272a;
          // border-radius: 10px;
          // padding: 10px;
          margin-bottom: 20px;
        }
      }
    }
    @include mobile {
      flex-direction: column;
      .action {
        :first-child {
          width: 80px;
          height: 80px;
        }
      }
      .statistics {
        width: 100%;
        flex-direction: column;
        align-items: center;
        .divider {
          width: 100%;
          height: 0;
          border-bottom: 1px solid #23272a;
        }
        .stat {
          align-items: center;
          padding: 50px 30px;
          height: 100px;
          margin: 40px 0;
          .input-title {
            width: 500px;
            margin: 10px 0;
            label {
              font-size: 30px;
            }
            input {
              font-size: 40px;
            }
          }
        }
      }
    }
  }
}
