@import "theme/theme.scss";

.list_links {
  width: 500px;
  .btn_link {
    padding-top: 25px;
  }
  @include mobile {
    width: 100%;
    .btn_link {
      text-align: start;
      padding-left: 10px;
    }
    a {
      font-size: 18px !important;
      padding: 5px 15px;
      margin: 0 !important;
    }
  }
}

.link_container {
  display: flex;
  flex-direction: column;
  padding: 5px 50px;
  width: 100%;
  font-family: "Work Sans";
  .item_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 30px;
    cursor: pointer;
    .icon {
      font-size: 30px;
    }
    .main_item {
      font-size: 30px;
      font-weight: 700;
    }
    &:hover {
      color: #999;
    }
  }
}

.list_sub_items {
  animation-name: dropdown;
  animation-duration: 1s;
  padding-left: 15px;
  .sub_item {
    font-family: "Work Sans";
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 23px;
    cursor: pointer;
    &:hover {
      color: #999;
    }
  }
}

.redirectBtnWhite {
  a {
    font-size: 15px !important;
  }
}

@include mobile {
  .MuiDrawer-paperAnchorRight {
    left: 0;
    width: 100%;
  }
  .link_container {
    padding-left: 10px;
    .item_container {
      // width: 70%;
      padding: 0 15px;
      .main_item {
        font-size: 18px;
        padding-bottom: 0px;
      }
      .icon {
        font-size: 20px;
      }
    }
    .list_sub_items {
      animation-name: dropdown;
      animation-duration: 1s;
      padding-left: 15px;
      height: 55px !important;
      .sub_item {
        // height: 45px !important;
       
        font-size: 15px;
        padding: 0 0 0 10px;
      }
    }
  }
}

@keyframes dropdown {
  from {
    opacity: 0;
    transform: translate(-50px);
  }
  to {
    opacity: 1;
    transform: translate(0);
  }
}

.vertical_close_drawer {
  position: fixed;
  right: 0px;
  top: 0;
  width: 50px;
  display: flex;
  background-color: white;
  z-index: 12;
  .vertical_content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .menu_icon {
      cursor: pointer;
    }
  }
}

.horizontal_navbar {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  min-height: 40px;
  display: flex;
  background-color: white;
  z-index: 12;
}
