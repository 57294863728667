.fetching-screen {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carte-container {
  flex: 1;
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  // z-index: 2;
  // width: 600px;
  // height: 600px;
  .controls {
    margin: 0 10px;
    width: 320px;
    padding: 1rem;
    overflow-y: scroll;
  }
  .map-content {
    position: relative;
    flex: 1;
    height: 100%;
    border-radius: 20px;
    box-shadow: 1px 1px 5px black;
  }
  .marker-container {
    position: absolute;
    z-index: 1;
    &:hover {
      z-index: 2;
    }
    .paper {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 200px;
    }
  }
}

.carte-container-real-time {
  flex: 1;
  // position: absolute;
  display: flex;
  // top: 0;
  // bottom: 0;
  // right: 0;
  // left: 0;
  // z-index: 2;
  // width: 600px;
  // height: 600px;
  .controls {
    margin: 0 10px;
    width: 320px;
    padding: 1rem;
    overflow-y: scroll;
  }
  .map-content {
    position: relative;
    flex: 1;
    height: 100%;
    border-radius: 20px;
    box-shadow: 1px 1px 5px black;
  }
  .marker-container {
    position: absolute;
    z-index: 1;
    &:hover {
      z-index: 2;
    }
    .paper {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 200px;
    }
  }
}
