.formulaire-validation {
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: calc(100vh - 40px);

    &__header {
        span {
            font-weight: 700;
            font-size: 14px;
        }

        p {
            font-weight: 600;
            font-size: 12px;
        }
    }

    &__form-name {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__is-template {
        &__btns {
            display: flex;
            gap: 30px;
        }
    }
}