@import 'theme/theme.scss';

.draggable-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin: 0 0px 15px 0px;
  height: auto;
  transition: all 1s ease-in-out;

  .handle {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 20;
    background-color: #dfe6e9;
    svg {
      height: 100%;
      color: #23272A;
    }
  }
}
.section-btns {
  position: absolute;
  background-color: #dfe6e9;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .hide {
    color: #23272A;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    
  }
}
.hide {
  background-color: #dfe6e9;
  cursor: pointer;
    &:hover {
      background-color: #d4d4d4;
    }
}

.hidden-section {
  height: 70px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  padding-left: 50px;
  align-items: center;
  box-shadow: 0 2px 10px -5px black !important;
  p {
    padding: 0 30px;
    font-family: Overpass;
    font-size: 30px;
  }
  @include mobile {
    height: 120px;
    p {
      font-size: 50px;
    }
    .drag {
      width: 80px;
    }
    ._hide {
      width: 80px;
      height: 80px;
    }
  }
}

.show-section {
  transition: all 1s ease-in-out;
  height: 100%;
  flex-grow: 1;
  width: 900px;
  margin: 0px auto;
}

.fade-in {
  opacity: 0;
  animation: fadeIn .5s forwards;
}

.fade-out {
  opacity: 1;
  animation: fadeOut .5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
  1% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
  0% {
    opacity: 0;
  }
  1% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
