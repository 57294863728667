.email-chip-container {
    display: flex;
    padding: 4px 10px;
    align-items: center;
    gap: 7px;
    border: 1px solid #6d6d6d;
    border-radius: 20px;
    width: fit-content;
    font-size: 13px;
    font-weight: 500;

    &__content {
        white-space: nowrap;
        user-select: none;
    }

    &__icon {
        display: flex;
        cursor: pointer;
    }
}