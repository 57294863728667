.campaignmodif-details_title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .back-arrow {
    height: 20px;
    width: 20px;
    padding: 10px;
    cursor: pointer;
  }

  .campaignmodif-state-container {
    padding: 10px;
    border-radius: 50px;
    margin-left: 20px;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    .container-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 160px;
      font-size: 12px;
      font-weight: bold;

      .campaignmodif-state-dot {
        padding: 2px 9px;
        margin-right: 10px;
        border-radius: 50px;
      }
    }
  }
}

.popover-campaignmodif-container {
  border: 1px solid black;
  padding: 5px;
  border-radius: 0px;

  .popover-item {
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 800;

    &:hover {
      text-shadow: 1px 1px 2px grey;
    }
  }
}

.voir-devis-btn {
  user-select: none;
  color: white;
  background-color: #23272A;
  border-radius: 50px;
  width: 130px;
  cursor: pointer;
  height: 44px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
}
