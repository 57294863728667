.filter-container {
  width: 30px;
  padding: 0;
  height: 45px;
  margin: 0;
  margin-left: 5px;
  border: none;
  box-shadow: 'none'
  svg {
    text-align: center;
  }
  .MuiOutlinedInput-notchedOutline { border: 0 }
}
