.mission-view-container {
  color: black;
  font-size: 10px;
  padding: 3px 10px 3px 5px;
  font-weight: 600;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    height: 15px;
    cursor: pointer;

    svg {
      height: 15px;
    }

    .map-vision {
      visibility: hidden;
      width: 24px;
      height: 24px;
      margin-top: 4px;
      margin-left: 5px;
      display: flex;
      align-self: center;
    }

    .map-vision-true {
      visibility: visible;
      width: 24px;
      height: 24px;
      margin-top: 4px;
      margin-left: 5px;
      display: flex;
      align-self: center;
      color: "#60EED2" !important
    }
  }
  .mission-name {
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    &.isHover {
      white-space: normal;
    }
  }
  &.isHover {
    background-color: #ecfbf8;
  }
  &.selected {
    background-color: rgb(227, 227, 227);
  }
  &.multiple {
    background-color: #cef9f1;
  }
  &.move {
    background-color: rgb(197, 197, 197);
    opacity: 0.3;
  }
  &.hidden {
    opacity: 0.3;
  }
  &.mission-on-break-slot-container {
    background-color: rgba(243, 185, 34, 0.698);
  }
}