.slot-details {

  .slot-details_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .slot-details_title-firstContent {
      font-size: 16px;
      font-weight: 800;

    }

    .slot-details_title-secondContent {
      display: flex;
      align-items: center;
      gap: 10px;

      .slot-state {
        padding: 10px;
        border-radius: 50px;
        margin-left: 20px;

        .slot-state-dot {
          padding: 0 8px;
          margin-right: 10px;
          border-radius: 50px;
        }
      }

      .slot-pen {
        background-color: black;
        padding: 5px;
        border-radius: 50px;
        cursor: pointer;
      }
    }
  }

  .slot-details_content {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 20px;
    font-size: 12px;
    font-weight: 800;
  }

  .slot-details_logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
  }
}