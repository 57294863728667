.formulaire-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100vh - 40px - 40px - 30px);
    gap: 20px;

    &__section {
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.14);
        border-radius: 20px;
        padding: 15px 10px 15px 15px;
        box-sizing: border-box;
        overflow: auto;

        &--questions-container {
            flex: 0 0 300px;
            gap: 20px;

            &__items {
                overflow: auto;
                scrollbar-width: thin;
                scrollbar-color: #c1c1c1 transparent;
                padding-right: 5px;
            }

            &__btns {
                display: flex;
                flex-direction: column;
                margin-top: auto;
                gap: 15px;
            }

            &__list {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }

        &--question-details {
            flex: 0 0 calc(100% - 300px - 20px);
        }
    }
}