.list-container {
    flex: 1;
    display: flex;
    padding: 10px 10px 10px 0;
    .list-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        .bookmark-list {
            background-color: white;
            border-radius: 5px 5px 0 0;
            padding: 7px 10px 7px 0;
            display: flex;
            .bookmark-title {
                font-size: 16px;
                margin: 15px 30px;
            }
            .bookmark-filter {
                position: relative;
                .filter-icon {
                    position: absolute;
                    right: 0;
                    top: 44%;
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: translate(0%, -50%);
                    margin-right: 10px;
                    border: 1px solid var(--black-ffy);
                    cursor: pointer;
                    &:hover {
                        background-color: rgb(194, 194, 194) !important;
                    }
                }
            }
            .temporary-filter {
                flex: 1;
                min-width: 300px;
                border: 1px solid var(--black-ffy);
                border-radius: 50px;
                padding: 15px;
                font-size: 12px;
                align-self: center;
            }
        }
    }
}