.report-issue-container {
    position: absolute;
    bottom: 160px;
    right: 0;
    z-index: 9999;
}

.report-issue-inner {
    position: relative;
}

.open-modal-btn {
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    margin-left: 20px;
    width: 90px;
    height: 60px;
    background: #23272a;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.modal-overlay {
    position: absolute;
    bottom: 80px;
    right: 20px;
}

.modal-content {
    background: #fff;
    border-radius: 5px;
    width: 495px;
    height: 550px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    z-index: 99999;
    position: relative;
}

.modal-header {
    border-bottom: 1px solid #ccc;
    text-align: center;
    padding: 20px 0;
    font-weight: 700;
    font-size: 15px;
}

.modal-body {
    border-bottom: 1px solid #ccc;
    padding: 20px;
}

.section-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 30px;
}

.input-group {
    margin-bottom: 20px;
}

.media-section {
    padding: 20px;
}

.media-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 10px;
    max-height: 260px;
    overflow-y: scroll;
}

.upload-media-btn {
    width: 175px;
    height: 100px;
    border: 1px solid #000;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.upload-media-icon {
    font-weight: 700;
    font-size: 35px;
    border: 1px solid #000;
    border-radius: 50px;
    padding: 0 15px;
}

.media-item {
    position: relative;
    display: inline-block;
}

.uploaded-media {
    width: 175px;
    height: 100px;
    border: 1px solid #000;
    border-radius: 10px;
}

.media-close {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #ff4d4f;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}

.submit-button {
    margin: 20px;
    position: absolute;
    bottom: 0;
    width: 80%;
    font-weight: 700;
    font-size: 15px;
}