@import 'theme/theme.scss';
.comments_container {
  padding: 35px 20px;
  background-color: white;
  .comments-content {
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: space-between;
    padding: 30px 20px 0 20px;
    .avatar {
      width:100px;
      height: 100px;
      img {
        height: 100px;
      }
  }
    .input-comments {
      flex: 1; 
      max-height: 300px;
      // background-color: #23272a; 
      // border-radius: 10px; 
      // padding: 10px;
    }
    @include mobile {
      flex-direction: column;
      .input-comments {
        width: 90%;
        :nth-child(1n) {
          font-size: 30px;
        } 
      }
    }
  }
}