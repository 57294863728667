.zones-list-modal {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.zones-list-modal-content {
  background-color: white;
  height: 100%;
  overflow-y: auto;
  position: relative;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 900px;
  gap: 20px;
  .zones-list-modal-header-container {
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    .zones-list-modal-header-title {
      font-size: 16px;
      font-weight: 800;
      color: black;
    }
    .zones-list-modal-header-selected-count {
      font-size: 14px;
      font-weight: 700;
      color: black;
    }
  }
  .zones-list-modal-submit-btn {
    background-color: var(--black-ffy);
    border: 1px solid var(--black-ffy);
    color: white;
    padding: 15px 70px;
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    user-select: none;
    width: fit-content;
    margin: auto;
    margin-bottom: 20px;
  }
}

.add-filters-btn-container {
  max-width: 850px;
}

.sticky-header th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
}

.existing-zone-filter-popover-collapse-cities-autocomplete {
  padding: 20px;
}