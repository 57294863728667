.new-campaign-wrapper {
  display: flex;
  flex-direction: row;
}
.campaign-modification-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.component-campaign-modification {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 40vw;
  overflow: auto;
  padding: 30px;

  .save-campaign {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex: 1;

    .save-campaign-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
  }

  .loading-container {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 50vw;
  }
}
