.anomalie-container {
  background-color: white;
  margin-top:10px;
  margin-bottom: 5px;
  .children {
    &.hidden {
      height: 0px;
      overflow: hidden;
    }
  }
  .anomalie-title-container {
    color: black;
    font-weight: 600;
    border: 2px solid black;
    display: flex;
    align-items: center;
    padding-left: 5px;
    cursor: pointer;
    .dropdown {
      width: 10px;
      height: 10px;
      margin-top: -1px;
      &.rotate {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
      }
    }
    &.isSelected {
      border: 4px solid #60EED2;
    }
    &.hidden {
      opacity: 0.3;
    }
    .anomalie-title {
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 80%;
      align-content: center;
      padding: 7px 10px 7px 5px;
    }
  }
}

.new-anomalie-btn {
  background-color: #000;
  text-align: center;
  font-size: 12px;
  padding: 8px 0px;
  color: white;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: rgb(55, 55, 55)
  }
}