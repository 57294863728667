.btn-styles {
  padding: 10px 30px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.6
  }
  &.regular {
    border-radius: 5px
  }
  &.rounded {
    border-radius: 50px
  }
  &.isIcon {
    opacity: 1;
    cursor: default;
  }
  &.disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 1;
  }
}