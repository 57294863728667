.existing-spots-modal {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.existing-spot-filter-popover-collapse-cities-autocomplete {
  padding: 20px;
}

.existing-spots-modal-content {
  background-color: white;
  height: 100%;
  overflow-y: auto;
  position: relative;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 900px;
  gap: 20px;
  .existing-spots-modal-header-container {
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    .existing-spots-modal-header-title {
      font-size: 16px;
      font-weight: 800;
      color: black;
    }
    .existing-spots-modal-header-selected-count {
      font-size: 14px;
      font-weight: 700;
      color: black;
    }
  }
  .existing-spots-modal-submit-btn {
    background-color: var(--black-ffy);
    border: 1px solid var(--black-ffy);
    color: white;
    padding: 15px 70px;
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    user-select: none;
    width: fit-content;
    margin: auto;
    margin-bottom: 20px;
  }
}

.sticky-header th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
}

.confirmation-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-modal-content {
  display: flex;
  flex-direction: column;
  gap: 35px;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  width: 550px;
  max-width: 90vw;
  text-align: center;
  .confirmation-modal-title {
    font-size: 14px;
    font-weight: 800;
    color: black;
  }
  .confirmation-modal-description {
    font-size: 14px;
    font-weight: 600;
    color: black;
  }
  .confirmation-modal-submit-btn {
    background-color: var(--black-ffy);
    border: 1px solid var(--black-ffy);
    color: var(--blue-green);
    padding: 15px 70px;
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    user-select: none;
    width: fit-content;
    margin: auto;
  }
}
.add-filters-btn-container {
  max-width: 850px;
}

.existing-spot-filter-popover-collapse-text {
  padding: 20px;
  font-size: 10px;
  font-weight: 600;
  color: #23272a;
}

.existing-spot-filter-popover-collapse-stars-container {
  display: flex;
  gap: 8px;
  padding: 0 20px 20px 20px;
}