.circle-icon {
    flex: 1;
    min-height: 12px;
    min-width: 12px;
    margin-right: 6px;
    margin-left: 2px;
}

.flash {
  animation: flash-animation 700ms infinite alternate;
}

@keyframes flash-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
