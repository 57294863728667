.initials-container {
  position: relative;
  padding: 10px;
  color: black;
  border-bottom: 1px solid black;
  text-align: center;
  .add-initial-btn {
    cursor: pointer;
    line-height: 1;
    top: 17%;
    position: absolute;
    right: 10px;
    padding: 5px;
    border: 1px solid black;
    border-radius: 5px;
    &.active {
      background-color: #60EED2;
    }
  }
}