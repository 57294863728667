.box {
    font-family: var(--open-sans);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    box-shadow: 1px 1px 2px 2px var(--grey);
    border-radius: 11px;
    padding: 4rem;
    text-align: center;
  }
  
  .buttons {
    display: flex;
    justify-content: space-around;
  }