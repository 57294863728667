.leftLayoutContent {
  pointer-events: all;
  max-height: calc(100% - 20px);
  background-color: #fff;
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE 10+ */

  .mission-name {
    padding: 10px 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background-color: #60eed23c;
    }
  }

  .mission-name.selected {
    background-color: #60eed23c;
  }
}

.leftLayoutContent.empty {
  .title {
    text-align: center;
    margin: 0;
  }
}

.leftLayoutContent::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.infoWindowContainer {
  width: 200px;
  height: 50px;
  background-color: white;
  z-index: 999;
  display: flex;
  align-items: center;
  padding: 5px;

  img {
    object-fit: cover;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
  }
}

div[aria-label="infoWindowRealTimeSM"] {
  padding: 0 !important;
  background-color: #fff !important;

  .gm-style-iw-d {
    overflow: unset !important;
    padding: 0 !important;
    display: flex;
    flex: 1;
  }

  .gm-style-iw-chr {
    display: none;
  }
}

div[aria-label="infoWindowRealTimeSMHover"] {
  border-radius: 50% !important;
  padding: 0 !important;
  width: 100px;
  height: 100px;
  background-color: #60eed2 !important;

  .gm-style-iw-d {
    overflow: unset !important;
    padding: 0 !important;
    display: flex;
    flex: 1;
  }
  button[aria-label="Fermer"] {
    display: none !important;
  }
}

.realtime-spot-infowindow-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;

  .realtime-spot-key-value-conatiner {
    display: flex;
    gap: 10px;
    .spot-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: black;
      cursor: pointer;
    }
    .spot-key {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
      font-size: 16px;
      color: black;
    }
  }
  .realtime-spot-close-infoWindow {
    position: absolute;
    top: 0;
    right: 0;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    padding: 4px;
    cursor: pointer;
    font-weight: 700;
  }
}
