.component-slot-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 40vw;
  overflow: auto;
  padding: 30px;

  .delete-slot {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex: 1;
    
    .delete-slot-button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 10px;
      text-decoration: underline;
      color: red;
      cursor: pointer;
    }
  }
}