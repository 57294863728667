@import 'theme/theme.scss';

.bloc-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
@include mobile {
  .image-container {
    height: unset !important;
  }
  .double-image {
    flex: 1;
    margin: 20px;
  }
}
