.right-layout {
  position: absolute;
  left: 10px;
  bottom: 25px;
  background-color: white;
  width: 10px;
  height: 200px;
  border-radius: 10px 0 10px 10px;
  transition: width .5s ease;

  &.right-layout-open {
    width: 300px;
    height: unset;

    .right-layout-chevron {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .right-layout-chevron {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0px; 
    right: -30px; 
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 10px 10px 0;

    svg {
      transition: transform .5s ease;
    }
  }
}