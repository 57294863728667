.model-right-stats-layout {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: flex-start;
  // gap: 10px;
  min-height: 650px;
}

.model-right-stats-layout-icon {
  cursor: pointer;
  display: flex;
  padding: 10px;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 10px 0 0 10px;
  // transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
  border-top: black solid 3px;
  border-left: black solid 3px;
  border-bottom: black solid 3px;
  border-right: none;
}

.model-right-stats-layout-second-el {
  height: 685px;
  width: 525px;
  background-color: white;
  border-radius: 0 0 0 10px;
  display: flex;
  flex-direction: column;
  border: black solid 3px;
  .stats-tabs-btns-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .stats-tabs-btn {
      border: 1px solid black;
      width: 100%;
      color: black;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      padding: 8px 0px;
      user-select: none;
      cursor: pointer;
      &.selected {
        background-color: black;
        color: white;
      }
    }
  }
  .stats-title {
    font-size: 20px;
    color: black;
    font-weight: 700;
    padding: 10px;
  }
  .stats-silder-tabs-content-container {
    padding: 10px;
    .times-tab-content-container {
      padding: 35px 10px;
      display: flex;
      flex-direction: column;
      color: black;
      gap: 15px;
      font-size: 14px;
      font-weight: 700;
    }
    .stats-tab-content-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .stats-cards-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 15px;
      }
      .stats-charts-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 15px;
        .chart-container {
          border: 1px solid #999999;
          border-radius: 10px;
          padding: 8px;
          width: 215px;
        }
      }
    }
  }
}

.model-right-stats-layout.slide-in {
  right: 0; /* Slides into view */
}

.close-icon {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}
.open-icon {
  transform: rotate(-90deg);
  transition: transform 0.3s ease;
}

.stat-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #999999;
  padding: 6px;
  align-items: center;
  gap: 5px;
  flex: 1;
  justify-content: space-around;
  width: 135px;
  height: 130px;
  .stat-card-title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-transform: uppercase;
    text-align: center;
  }
  .stat-card-value {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: black;
  }
  .stat-card-unit {
    text-align: center;
    font-weight: 700;
    font-size: 10px;
    color: #666666;
  }
}