.responses-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;

    &__table {

        &__cell-container {
            border: 1px solid #ddd;
            max-width: 200px;
            min-width: 50px;

            &__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;

                &__text,
                ul {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                }
            }
        }
    }

    &__note {
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
    }
}