.picker-component {
  width: 100%;

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 50px;
    border-color: black;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: black solid 1px;
  }

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: black;
  }

  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px;
    font-size: 14px;
    font-weight: 700;
  }
}