.devis-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
}

.devis-modal-overlay.open {
  visibility: visible;
  opacity: 1;
}

.devis-modal-content {
  width: 700px;
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
  .key-value-table {
    width: 100%;
    padding: 30px 15px;

    tbody {
      tr {
        td {
          font-size: 14px;
          font-weight: 700;
          padding-bottom: 30px;
          vertical-align: top;

          &.key {
            text-align: left;
            width: 40%;
          }
        }
      }
    }
  }
}

.devis-modal-header-content {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  .devis-modal-title {
    font-size: 20px;
    font-weight: 800;
  }
}

.devis-modal-btns-container {
  display: flex;
  gap: 70px;
  margin: 40px 0;
  padding: 0 15px;
  .devis-modal-btn {
    user-select: none;
    color: white;
    background-color: #23272A;
    border-radius: 50px;
    width: 100%;
    cursor: pointer;
    height: 44px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
  }
}
