@import "theme/theme.scss";

.statistics_smarketing {
  padding: 65px 140px;
  .statistics_container {
    padding: 60px 0 55px 0;
    display: flex;
    justify-content: space-between;
    .statistics {
      display: flex;
      justify-content: space-between;
      // width: 33%;
      flex: 1;
      .stats_divider {
        border-left: 1px solid #23272a;
        margin: 0 40px 0 10px ;
        height: calc(100% + 35px);
      }
      .stat {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 40px;
          font-weight: 700;
          padding: 10px 0;
        }
        .text {
          font-size: 17px;
          font-weight: 600;
          width: 70%;
        }
      }
    }
    @include mobile {
      flex-direction: column;
      .statistics {
        width: 100%;
        flex-direction: column;
        align-items: center;
        .divider {
          width: 100%;
          height: 0;
          border-bottom: 1px solid #23272a;
        }
        .stat {
          align-items: center;
          padding: 50px 30px;
          .title {
            font-size: 45px;
          }
          .text {
            font-size: 30px;
          }
        }
        .stats_divider {
          width: 100%;
        }
      }
    }
  }
}
