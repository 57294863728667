.bookmark-prestation {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  border-radius: 10px 10px 0 0;
  color: black;
  font-size: 14px;
  font-weight: 700;
  .icons-right {
    display: flex;
    align-items: center;
  }
  .left-content {
    flex-direction: row;
    .prestation-name {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
}

.prestation-container {
  user-select: none;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; // Masquer la barre de défilement sur WebKit
  }
}

.publish-map-button {
  color: rgba(96, 238, 210, 1);
  font-size: 15px;
  background-color: black;
  font-weight: 700;
  border-radius: 50px;
  width: 100%;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  pointer-events: all;
  user-select: none;
}