.view-photo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  border-radius: 10px;
  background-color: white;
  color: black;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .header {
    display: flex;
    justify-content: space-between;

    .close-icon {
      fill: black;
      cursor: pointer;
      width: 25px;
      height: 25px;
    }
  }

  .images-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;

    .image-wrapper {
      position: relative;
      cursor: pointer;
    }

    .checkmark-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      background-color: black;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      .check-icon {
        fill: #60eed2;
        width: 20px;
        height: 20px;
      }
    }

    .image {
      width: 150px;
      height: 150px;
      padding: 5px;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin: 20px;
  }
}
