.prestation-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40vw;
    overflow: auto;
    padding: 30px;

    .bottom-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;

        .removed-prestation {
            color: red;
            margin-top: 30px;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
        }
    }
}