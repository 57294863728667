.input-component {
  width: 100%;

  &.info {
    margin: 20px 0;
  }

  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--blue-green);
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 50px;
    border-color: black;
  }

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: black;
  }
}

.slotmodif-component-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  .input-component-typeCost {

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border-radius: 50px;
      border-color: black;
    }

    .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
      color: black;
    }

    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: var(--blue-green);
    }
  }
}

.input-address {
  margin-top: 20px;

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 50px;
    border-color: black;
  }

  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--blue-green);
  }

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: black;
  }
}