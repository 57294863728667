.usecase-logo {
  position: relative;
  height: 80px;
  width: 100px;
  display: flex;
  align-items: center;
  .upload-logo {
    width: 100px !important;
  }

}
.upload-logo {
  object-fit: cover;
  &:hover {
    cursor: pointer;
    filter: brightness(0.5);
    animation: filter 0.5s ease-in-out;
    .select-logo {
      color: white
    }
  }
}

.inputImage-temp {
  input {
    width: 1px;
    height: 1px;
  }
}

.select-logo {
  position: absolute;
  cursor: pointer;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.upload-image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.upload-left {
  position: relative;
  height: 100%;
  background-color: #dfe6e9;
}
