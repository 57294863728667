.grid-prestation-container {
    display: grid;
    grid-template-columns: 200px 0.5fr;
    gap: 15px 5px;
    margin-top: 30px;

    &.full {
        margin-top: 15px;
        grid-template-columns: 200px 1fr;
    }

    .labelized-grid {
        align-self: center;
    }
}

.hand-form-formulaire-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;

    &__label-name-container {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__btns {
        display: flex;
        gap: 50px;
        justify-content: space-evenly;
    }
}

.prestation-hand-form-modals-container {
    display: flex;
    flex-direction: row;
}