.infos-candidate-container {
  background-color: var(--grey-ffy);
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;


  .candidate-state-container {
    padding: 10px;
    border-radius: 50px;
    margin-left: 20px;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
    
    .container-flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 160px;
      font-size: 12px;
      font-weight: bold;
      
      .candidate-state-dot {
        padding: 2px 9px; 
        margin-right: 10px;
        border-radius: 50px;
      }
    }
  }

}

.popover-candidate-container {
  border: 1px solid black;
  padding: 5px;
  border-radius: 0px;

  .popover-item {
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 800;

    &:hover {
      text-shadow: 1px 1px 2px grey;
    }
  }
}