.slot-card-container {
    display: flex;
    justify-content: flex-end;
    height: 100%;

    .slot-card-content {
        background-color: white; 
        height: 100%;

        > * {
            height: -webkit-fill-available;
            color: black;
            overflow: auto;
        }
    }
}
