@import "theme/theme.scss";
.v_comments_container {
  padding: 100px 120px 50px 120px;
  background-color: white;
  .comments-content {
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: space-between;
    padding: 30px 20px 0 20px;
    .avatar {
      width: 100px;
      height: 100px;
      img {
        height: 100px;
      }
    }
    .comments {
      flex: 1;
      padding: 10px;
      p {
        width: 750px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 24px;
        color: #23272a;
        font-weight: 600;
        font-family: Work Sans;
      }
    }
  }
  @include mobile {
    padding: 100px 30px;
    .comments-content {
      flex-direction: column;
      .input-comments {
        width: 90%;
      }
      .comments {
        width: 80%;
        p {
          width: 100%;
          font-size: 30px;
        }
      }
    }
  }
}
