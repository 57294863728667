.v_videoPlayer {
  width: 100%;
  height: 100%;
  :global(iframe),
  .thumbnail {
    // border-radius: 20px;
    border: none;
    width: 100%;
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}

.thumbnail {
  height: 554px;
  width: 100%;
  position: relative;
  &Img {
    height: 100%;
    width: 100%;
    // border-radius: 20px;
    object-fit: cover;
    filter: brightness(60%);
  }
  .playIcon svg,
  .playIconMain svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
  .playIcon svg {
    height: 80px;
    width: 80px;
  }
}
