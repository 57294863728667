.adding-initial-btn {
    background-color: white;
    border: 1px solid black;
    color: black;
    padding: 0px 4px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
}

.first-line-zone-init {
    justify-content: space-between;
    position: relative;
    font-size: 12px;
    display: flex;
    border-bottom: 1px solid black;
    align-items: center;
    padding: 13px;
}