@import "theme/theme.scss";

.home-container {
  width: 100%;
  overflow-y: auto;
  .add-search {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    @include mobile {
      padding: 0 20px 10px 20px;
      flex-direction: column;
      row-gap: 10px;
    }
  }
  .masonry {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    // padding: 0 20px;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 4px;
    height: 200px;
  }
  
  .no-usecase {
    background-color: white;
    width: 100%;
    height: 540px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
