.filter-component-container {
  display: flex;
  flex-direction: column;

  .add-filters-btn {
    color: var(--black-ffy);
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    padding: 0 24px;
  }

  .filter-modal-filters-container {
    padding: 0 24px;
    display: flex;
    gap: 20px;
    margin-top: 12px;
    flex-wrap: wrap;

    .filter-modal-filter-container {
      display: flex;
      gap: 15px;
      background-color: #23272a;
      border-radius: 25px;
      padding: 12px 16px;
      align-items: center;
      flex-wrap: wrap;

      .filter-modal-filter-item {
        display: flex;
        color: #23272a;
        background-color: white;
        border-radius: 30px;
        padding: 6px 12px;
        font-size: 10px;
        font-weight: 700;
        user-select: none;
        gap: 8px;
        align-items: center;
      }
      .filter-modal-filter-single-item {
        display: flex;
        color: white;
        border-radius: 30px;
        padding: 6px 12px;
        font-size: 10px;
        font-weight: 700;
        -webkit-user-select: none;
        user-select: none;
        gap: 20px;
        align-items: center;
      }
    }
  }
}

.filter-popover-container {
  width: 400px;
  max-height: 700px;

  .filter-popover-collapse-title-button {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    padding: 14px;
    border-bottom: 1px solid #c6c6c6;
    font-size: 12px;
    font-weight: 700;
    color: var(--black-ffy);
    user-select: none;
    .arrow {
      transition: transform 0.3s ease-in-out;
      &.open {
        transform: rotate(270deg);
      }
      &.close {
        transform: rotate(180deg);
      }
    }
  }

  .filter-popover-collapse-item {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #c6c6c6;
    cursor: pointer;
    font-size: 11px;
    font-weight: 700;
    align-items: center;
    user-select: none;
  }

  .filter-popover-collapse-text {
    padding: 20px;
    font-size: 10px;
    font-weight: 600;
    color: #23272a;
  }

  .filter-popover-collapse-stars-container {
    display: flex;
    gap: 8px;
    padding: 0 20px 20px 20px;
  }
}
