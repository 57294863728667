.filter-component-invoices-container {
    background-color: white;
    display: flex;
}

.status-dropdown-container {
    position: absolute;
    top: 40px;
    left: 0;
    background-color: white;
    border: 1px solid #000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 180px;
    border-radius: 10px;

    &__item {
        padding: 8px 12px;
        cursor: pointer;
        font-size: 12px;

        &:hover {
            color: #838383;
        }
    }
}