.cadence-spot-infowindow-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 0 12px 12px;
    .cadence-spot-infowindow-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 10px;
    }
    .cadence-spot-title-conatiner {
      font-size: 16px;
      font-weight: 700;
      color: black;
    }
    .cadence-spot-key-value-conatiner {
      display: flex;
      gap: 10px;
      max-width: 350px;
      .spot-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: black;
      }
      .spot-key {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        font-size: 14px;
        color: black;
      }
    }
    .cadence-spot-close-infoWindow {
      position: absolute;
      top: 0;
      right: 0;
      border-bottom: 1px solid #000;
      border-left: 1px solid #000;
      padding: 4px;
      cursor: pointer;
      font-weight: 700;
    }
  }