.close-map-btn {
  color: white;
  background-color: black;
  border-radius: 50px;
  width: 100%;
  cursor: pointer;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
}

.model-bottom-contentMaps {
  position: absolute;
  bottom: 0px;
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 50%;
  transform: translate(-50%, 0%);
  transition: transform 0.3s ease-in-out;
  border-radius: 10px;
  width: 400px;
}



.model-bottom-contentMaps-second-el {
  display: flex;
  gap: 30px;
  width: 100%;
  border: black solid 3px;
  border-bottom: none !important;
  height: 100%;
  padding: 10px 30px;
  justify-content: space-between;
  background-color: white;
  border-top-left-radius: 30px;
  transition: transform 0.3s ease;
  border-top-right-radius: 30px;
}

.model-bottom-contentMaps-icon {
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  padding: 10px;
  width: 12px;
  height: 12px;
  border-bottom: none !important;
  border: black solid 3px;
  background-color: white;
}

.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.model-bottom-contentMaps.slide-in {
  transform: translate(-50%, 63%);
  transition: transform 0.3s ease;
}